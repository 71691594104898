import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

function ready(fn) {
  if (document.readyState != "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

ready(function () {
  // Workaround for closing menu when clicking an anchor link
  var menu = document.getElementById("menu");
  var menuToggler = document.getElementById("menu-toggler");

  menu.addEventListener("click", handleMenuClick);

  function handleMenuClick(event) {
    if (event.target instanceof HTMLAnchorElement) {
      menuToggler.checked = false;
    }
  }

  // smooth scroll for anchor links
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
});
